import React from 'react';

import Layout from '../../components/Layout';
import CaseStudyRoll from '../../components/CaseStudyRoll/CaseStudyRoll';

export default class CaseStudyIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <CaseStudyRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
