import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import PreviewCompatibleImage from '../PreviewCompatibleImage';
import './CaseStudyRoll.scss';

class CaseStudyRoll extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <div className="caseStudyRoll">
        {posts
          && posts.map(({ node: post }, index) => (
            <div className="columns is-multiline caseStudy-inRoll" key={index}>
              <div className="is-parent column is-6" key={post.id}>
                <article>
                  <header>
                    <p>{post.frontmatter.client}</p>
                    <h4>
                      {post.frontmatter.title}
                    </h4>
                  </header>

                  <Link to={post.fields.slug}>
                    <p>
                      <span className="blogRoll--button">View</span>
                      <span style={{ marginLeft: '8px' }}>→</span>
                    </p>
                  </Link>

                </article>
              </div>
              <div className="column is-6">
                <header>
                  {post.frontmatter.featuredimage ? (
                    <div className="featured-thumbnail">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                        }}
                      />
                    </div>
                  ) : null}
                </header>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

CaseStudyRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query CaseStudyQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "case-study" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                client
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 605, maxHeight: 338, quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <CaseStudyRoll data={data} count={count} />}
  />
);
